import React from "react";

export default function Navbar() {
  return (
    <div className="sticky-top">
      <nav className="navbar navbar-expand-lg bg-body-tertiary navbar-style shadow p-3 bg-body-tertiary sticky-top ">
        <div class="container-fluid">
          <a class="navbar-brand logo" href="#">
            <img src="/assets/img/tutorio-logo-3.png" alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav ms-auto mb-0 mb-lg-0">
              <li class="nav-item nav-item-style">
                <a class="nav-link active px-4" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item nav-item-style">
                <a class="nav-link px-4" href="#features">
                  Features
                </a>
              </li>
              {/* <li class="nav-item nav-item-style">
                <a class="nav-link px-4" href="#contact">
                  Blogs
                </a>
              </li> */}
               <li class="nav-item nav-item-style">
                <a class="nav-link px-4" href="#about">
                 About Us
                </a>
              </li>
              <li class="nav-item nav-item-style">
                <a class="nav-link px-4" href="#get quote">
                  Get Quote
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
